import axios, { AxiosResponse } from 'axios';
import pRetry from 'p-retry';

export const withAuthRetryAxios = async <T = any>(
  apiCall: () => Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> =>
  pRetry(
    async () => {
      const res = await apiCall();
      return res;
    },
    {
      retries: 1,
      onFailedAttempt: (err) => {
        // Retry when JWT token is expired
        const canRetry = axios.isAxiosError(err) && err.response?.status === 401;

        if (!canRetry) {
          throw err;
        }
      },
    },
  );
