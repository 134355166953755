import { BaseQueryFn, createApi, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

// Define a service using a base URL and expected endpoints
export const adminApi = createApi({
  reducerPath: 'admin-api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ['plan', 'plan/installment', 'transaction', 'user'],
});
