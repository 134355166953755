import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthToken } from '../helpers/auth';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers) => {
    const token = await getAuthToken();
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  },
});
